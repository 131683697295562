import { AbsoluteContainer, Cushion, Rectangle, Typography } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../icon';
import useBreakpoint from '../../../lib/use-breakpoint';
import ActivityStreamItem from '../item';
import MediaOverlay from '../media-overlay';

const AlbumMessage = ({ message, stacked, ...otherProps }) => (
  <ActivityStreamItem
    imageSrc={message.cover_image}
    isPrivate={message.isPrivate}
    linkProps={{
      route: 'media.album',
      params: { id: message.album_id, _title: message.title },
    }}
    totalImages={message.total_images}
    renderAfterImage={(isV2) => {
      if (isV2 && stacked === 'always') {
        const isMobile = useBreakpoint();
        return (
          <MediaOverlay isMobile={isMobile} publishedDate={message.published}>
            <Icon name="camera" color="white" size={isMobile ? 22 : 36} />
          </MediaOverlay>
        );
      }
      return (
        <AbsoluteContainer bottom={0} left={0} style={{ whiteSpace: 'nowrap' }}>
          <Rectangle
            fill="primary"
            radius={stacked === 'always' ? 'roundedTopRight' : 'roundedTopRightAndBottomLeft'}
            responsive={[
              {
                minWidth: 'tab',
                props: {
                  radius: stacked === 'never' ? 'roundedTopRightAndBottomLeft' : 'roundedTopRight',
                },
              },
            ]}
          >
            <Cushion all="xsmall" style={{ display: 'inline-block' }} component="div">
              <Icon name="camera" color="primaryContrast" preset="name" size={18} />
            </Cushion>
            <Cushion
              vertical="xsmall"
              right="xsmall"
              style={{ display: 'inline-block' }}
              component="div"
            >
              <Typography color="primaryContrast" preset="name">
                {message.total_images}
              </Typography>
            </Cushion>
          </Rectangle>
        </AbsoluteContainer>
      );
    }}
    messageType={message.type}
    published={message.published}
    title={message.title}
    stacked={stacked}
    {...otherProps}
  />
);

AlbumMessage.defaultProps = {
  stacked: 'always',
};

AlbumMessage.propTypes = {
  stacked: PropTypes.string,
  message: PropTypes.shape({
    album_id: PropTypes.number.isRequired,
    cover_image: PropTypes.string.isRequired,
    isPrivate: PropTypes.bool,
    published: PropTypes.string,
    title: PropTypes.string.isRequired,
    total_images: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default AlbumMessage;
